import styled, { keyframes } from "styled-components"
import themeSettings from "../../styles/theme"

const wobble = keyframes`
  0% { transform: skew(0) }
  20% { transform: skew(-10deg) }
  40% { transform: skew(10deg) }
  60% { transform: skew(-5deg) }
  80% { transform: skew(5deg) }
  90% { transform: skew(2deg) }
  100% { transform: skew(0) }
`

export const Section = styled.section`
  box-sizing: border-box;
  width: 100vw;
  max-width: 100%;
  margin: calc(${themeSettings.navHeight}px * 1.2) 0 ${themeSettings.margins.large} 0;
  padding: 0 ${themeSettings.margins.page};

  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }

  @media screen and (max-width: 414px) {
    padding: 0 ${themeSettings.mobileMargins.page};
  }

  & > div {
    
    &:first-child {
      @media screen and (max-width: 1024px) {
        margin-bottom: ${themeSettings.margins.large};
      }
    }

    & > div {
      & > h4 {
        text-transform: uppercase;
      }
      & > p {
        @media screen and (max-width: 1024px) {
          word-wrap: break-word;
          white-space: pre-line;
	        max-width: 100%;
        }
        margin-bottom: ${themeSettings.margins.page};
        & > a {
          display: inline-block;
          &:hover {
            animation: 0.8s ${wobble} ease-out;
          }
        }
      }
    }
  }
`

export const Sidebar = styled.div`
  flex-basis: 25%;

  @media screen and (max-width: 1024px) {
    flex-basis: 100%;
    max-width: 100%;
  }
`

export const Main = styled.div`
  flex-basis: 75%;
  @media screen and (max-width: 1024px) {
    flex-basis: 100%;
    max-width: 100%;
  }
  & div {
    column-count: 2;
    column-width: 50%;
    column-gap: ${themeSettings.margins.page};

    @media screen and (max-width: 1620px) {
      column-count: 1;
    }
  }
`