import React from "react"
import * as Styled from "./styled"

const Sidebar = props => {
  return (
    <Styled.Sidebar>
      <div dangerouslySetInnerHTML={{ __html: props.input.sidebar_body_text.html }} />
    </Styled.Sidebar>
  )
}

export default Sidebar