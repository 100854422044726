import React from "react"
import Sidebar from "./sidebar"
import Main from "./main"
import * as Styled from "./styled"

const LegalBody = props => {
  return (
    <Styled.Section>
      <Sidebar {...props} />
      <Main {...props} />
    </Styled.Section>
  )
}

export default LegalBody