import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { ThemeProvider } from "styled-components"
import LayoutDefault from "../layouts/default"
import LegalBody from "../components/legal-body"
import Footer from "../components/footer"

const ImprintPage = () => {

  const data = useStaticQuery(graphql`
    query ImprintQuery {
      prismicImprint {
        uid
        data {
          page_title {
            text
          }
          sidebar_body_text {
            html
          }
          body_text {
            html
          }
        }
      }
    }  
  `)

  const content = data.prismicImprint.data

  return (
    <ThemeProvider theme={{ mode: "light" }}>
      <LayoutDefault>
        <LegalBody input={ content } />
        <Footer />
      </LayoutDefault>
    </ThemeProvider>
  )
}

export default ImprintPage