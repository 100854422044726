import React from "react"
import * as Styled from "./styled"

const Main = props => {
  return (
    <Styled.Main>
      <div dangerouslySetInnerHTML={{ __html: props.input.body_text.html }} />
    </Styled.Main>
  )
}

export default Main